///----DEV

// export const serverURL = 'http://localhost:4040/';
// export const frontendURL = 'http://localhost:3030';

///----MOBILE

// export const serverURL = 'http://192.168.43.48:4040/';
// export const frontendURL = 'http://192.168.43.48:3030';

//--- M2

// export const serverURL = 'http://192.168.0.164:4040/';
// export const frontendURL = 'http://192.168.0.164:3030';

///----PROD
export const serverURL = 'https://arsh-backend.herokuapp.com/';
export const frontendURL = 'https://arsh.events';

//-Iphone
// export const serverURL = 'http://172.20.10.3:4040/';
// export const frontendURL = 'http://172.20.10.3:3030';

export const DAILY_ROOM_URL = 'https://arsh.daily.co/';
export const arshPaystackDefaultKey = 'pk_live_437af9d088f2f8709eb1e1a3542d9aafa24cb11e';
export const HOTJAR_ID = 5133912;

export const eventTypes = [
  'Hybrid Event',
  'Physical Event',
  'Virtual Event',
  'Exhibitions',
  'Virtual Conference',
  'Virtual Conference & exhibition',
];

export const sessionTypes = [
  'ARSH Video',
  'Youtube Live',
  'Facebook Live',
  'ARSH Video, Youtube & Facebook',
  'Zoom Meeting / Conference',
  'Zoom, Youtube & Facebook',
];

export const accept = {
  allfiles:
    'image/*, application/msword, application/doc, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/docx, appl/text, application/vnd.msword, application/vnd.ms-word, application/pdf, application/word, application/x-msw6, application/x-msword, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation',
  documents:
    'application/msword, application/doc, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/docx, appl/text, application/vnd.msword, application/vnd.ms-word, application/pdf, application/word, application/x-msw6, application/x-msword, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation',
  images: 'image/*,',
};

export const liveStreamPlatforms = [
  {
    id: 1,
    title: 'ARSH Live Video',
    description: 'Recommended.',
    logo: 'https://res.cloudinary.com/arsh-events/image/upload/v1655986973/systemfiles/arsh_logo_rzdnxd.png',
    link: '',
    identifier: 'arsh',
  },
  {
    id: 2,
    title: 'Zoom Video',
    description: 'Join this session via Zoom.',
    logo: 'https://res.cloudinary.com/arsh-events/image/upload/v1655986253/systemfiles/zoom_okolfh.png',
    link: 'zoom',
    identifier: 'zoom',
  },
  {
    id: 3,
    logo: 'https://res.cloudinary.com/arsh-events/image/upload/v1655986207/systemfiles/facebook-new-2019-logo-5A4671100B-seeklogo.com_jkdbco.png',
    title: 'Facebook Live Stream',
    description: 'Join this session via Facebook livestream.',
    link: 'facebook',
    identifier: 'facebook',
  },
  {
    id: 4,
    logo: 'https://res.cloudinary.com/arsh-events/image/upload/v1655987570/systemfiles/Frame_2_wrl5af.png',
    title: 'Youtube Live Stream',
    description: 'Join this session via Youtube livestream.',
    link: 'youtube',
    identifier: 'youtube',
  },
];

export const currencies = [
  {
    code: 'NGN',
    title: 'Nigerian Naira',
  },
  {
    code: 'USD',
    title: 'United States Dollar',
  },
  {
    code: 'ZAR',
    title: 'South African Rand',
  },
  {
    code: 'GHS',
    title: 'Ghanian Cedi',
  },
];

export const adminRoles = [
  { id: 1, title: 'Manager' },
  { id: 2, title: 'Supervisor' },
  { id: 3, title: 'Accreditator' },
  { id: 4, title: 'Assistant' },
  { id: 5, title: 'Chat Support' },
  { id: 6, title: 'Help Desk' },
];

export const careerLevels = [
  'Entry Level',
  'Junior level',
  'Intermediate Level',
  'Mid-Entry level',
  'Mid management',
  'Senior level',
  'Senior management',
  'Founder/ CEO, MD/Director',
];

export const titleList = [
  'Mr.',
  'Engr.',
  'Mrs.',
  'Ms.',
  'Master.',
  'Chief',
  'Alhaji',
  'Mallam',
  'Dr. (Doctor)',
  'Prof. (Professor)',
  'Hon. (Honorable)',
];

export const ageRange = ['24 and Below', '25-30', '31-40', '41-50', '51-60', '61-70', '70 and above'];

export const Sectors = [
  'Banking  and Finance.',
  'Charterers',
  'Consultants',
  'Development Organisations',
  'Diplomats/Foreign Envoy',
  'Equipment Suppliers',
  'Gas/LPG',
  'Government   (Regulators)',
  'Government (Operators)',
  'Government (Others)',
  'Haulage',
  'Industry Associations and Groups',
  'Insurance',
  'Integrated Oil and Gas',
  'International Petroleum Products Traders / Suppliers',
  'Jetty Operators',
  'Legal Services',
  'Lubricant Dealers and Suppliers',
  'Marine Support Services',
  'Petrochemical Plants',
  'Petroleum Depot Owners and Operators',
  'Petroleum Product Marketers',
  'Power',
  'Press',
  'Ship and Product Brokers',
  'Ship Owners',
  'Student',
  'Technology',
  'Tracking Services',
  'Terminal operators',
  'Cargo importers/exporters',
  'Shipping companies',
  'Shipping agents',
  'Marine surveyors',
  'Lawyers',
  'Ship brokers and agents',
  'Ship chandlers',
  'Freight forwarders',
  'Stevedoring companies',
];
export const countryList = [
  'Nigeria',
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas (the)',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (the)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic (the)',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros (the)',
  'Congo (the Democratic Republic of the)',
  'Congo (the)',
  'Cook Islands (the)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories (the)',
  'Gabon',
  'Gambia (the)',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See (the)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic (the)",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands (the)',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands (the)',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger (the)',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines (the)',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan (the)',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands (the)',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States Minor Outlying Islands (the)',
  'United States of America (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Åland Islands',
];

export const clients = {
  rows: [
    {
      id: 1,
      name: 'OTL Africa',
      event_name: 'OTL Africa Downstream Week 2024',
      image: 'https://res.cloudinary.com/arsh-events/image/upload/v1647635683/public/vayeipbmngh4qcggw7ef.png',
      code: 'otl2024',
      date: '2022-03-15T13:44:53.000Z',
      time: '2022-03-15T13:44:53.000Z',
    },
    // {
    //   id: 2,
    //   name: 'NIMS Secretariat',
    //   event_name: 'Nigeria International Maritime Summit',
    //   image: 'https://res.cloudinary.com/arsh-events/image/upload/v1647638310/public/l7apx7kwi86aexqovh0l.webp',
    //   code: 'nims2022',
    //   date: '2022-03-18T20:27:09.000Z',
    //   time: '2022-03-18T20:27:09.000Z',
    // },
    // {
    //   id: 3,
    //   name: 'Akabogu Law',
    //   event_name: 'Ship Acquisition and Maritime Finance Masterclass',
    //   image: 'https://res.cloudinary.com/arsh-events/image/upload/v1675351732/public/nkoivolemojzp3bgbajz.png',
    //   code: 'SAMFIN',
    //   date: '2022-03-18T20:27:09.000Z',
    //   time: '2022-03-18T20:27:09.000Z',
    // },
  ],
};

export const greenGreenThingsCreed = {
  a: { c: '#' },
  '#': { l: 'a' },
  b: { c: '!' },
  '!': { l: 'b' },
  c: { c: '+' },
  '+': { l: 'c' },
  d: { c: '^' },
  '^': { l: 'd' },
  e: { c: '&' },
  '&': { l: 'e' },
  f: { c: '%' },
  '%': { l: 'f' },
  g: { c: '*' },
  '*': { l: 'g' },
  h: { c: '(' },
  '(': { l: 'h' },
  i: { c: '{' },
  '{': { l: 'i' },
  j: { c: '#_!' },
  '#_!': { l: 'j' },
  k: { c: '+_' },
  '+_': { l: 'k' },
  l: { c: '!_c' },
  '!_c': { l: 'l' },
  m: { c: '&_&' },
  '&_&': { l: 'm' },
  n: { c: '_)' },
  '_)': { l: 'n' },
  o: { c: 'h_&&' },
  'h_&&': { l: 'o' },
  p: { c: '*_&' },
  '*_&': { l: 'p' },
  q: { c: 'k!!' },
  'k!!': { l: 'q' },
  r: { c: 'r_%^' },
  'r_%^': { l: 'r' },
  s: { c: '##pp_i' },
  '##pp_i': { l: 's' },
  t: { c: '^_q' },
  '^_q': { l: 't' },
  u: { c: '|' },
  '|': { l: 'u' },
  v: { c: '||@_' },
  '||@_': { l: 'v' },
  w: { c: '/?' },
  '/?': { l: 'w' },
  x: { c: 'kq!' },
  'kq!': { l: 'x' },
  y: { c: '*))' },
  '*))': { l: 'y' },
  z: { c: '_++' },
  '_++': { l: 'z' },

  //CAP
  A: { c: '#-' },
  '#-': { l: 'A' },
  B: { c: '!-' },
  '!-': { l: 'B' },
  C: { c: '+-' },
  '+-': { l: 'C' },
  D: { c: '^-' },
  '^-': { l: 'D' },
  E: { c: '&-' },
  '&-': { l: 'E' },
  F: { c: '%kk' },
  '%kk': { l: 'F' },
  G: { c: '*!' },
  '*!': { l: 'G' },
  H: { c: '(-' },
  '(-': { l: 'H' },
  I: { c: '{-' },
  '{-': { l: 'I' },
  J: { c: '#_p!' },
  '#_p!': { l: 'J' },
  K: { c: '+_-' },
  '+_-': { l: 'K' },
  L: { c: '!_c-' },
  '!_c-': { l: 'L' },
  M: { c: '&_&-' },
  '&_&-': { l: 'M' },
  N: { c: '_)-' },
  '_)-': { l: 'N' },
  O: { c: 'h_&&-' },
  'h_&&-': { l: 'O' },
  P: { c: '*_&q' },
  '*_&q': { l: 'P' },
  Q: { c: 'k!!a' },
  'k!!a': { l: 'Q' },
  R: { c: 'r_%^q' },
  'r_%^q': { l: 'R' },
  S: { c: '##pp_i-' },
  '##pp_i-': { l: 'S' },
  T: { c: '^_q-' },
  '^_q-': { l: 'T' },
  U: { c: '|-' },
  '|-': { l: 'U' },
  V: { c: '||@_!' },
  '||@_!': { l: 'V' },
  W: { c: '/?-' },
  '/?-': { l: 'W' },
  X: { c: 'kq!-' },
  'kq!-': { l: 'X' },
  Y: { c: '*))q' },
  '*))q': { l: 'Y' },
  Z: { c: '_++z' },
  '_++z': { l: 'Z' },
  '.?': { l: ' ' },

  //NUMBERS
  1: { c: '=_+' },
  '=_+': { l: '1' },
  2: { c: '*@(' },
  '*@(': { l: '2' },
  3: { c: '#@(' },
  '#@(': { l: '3' },
  4: { c: '+_))' },
  '+_))': { l: '4' },
  5: { c: 'L><!' },
  'L><!': { l: '5' },
  6: { c: '_+_' },
  '_+_': { l: '6' },
  7: { c: '<>:' },
  '<>:': { l: '7' },
  8: { c: '{:' },
  '{:': { l: '8' },
  9: { c: '(*#@' },
  '(*#@': { l: '9' },
  0: { c: '&@#' },
  '&@#': { l: '0' },
};

const ggTEncrypt = (l) => l.replace(l, greenGreenThingsCreed[l].c);
const ggTDecrypt = (c) => c.replace(c, greenGreenThingsCreed[c].l);

export const encrypt = (str = '') => {
  const str_array = str.split('');
  const encrypted = [];
  for (let i = 0; i < str_array.length; i++) {
    const item = str_array[i];
    if (/[a-zA-Z0-9]/.test(item)) {
      const ggTEncryptedValue = ggTEncrypt(item);
      encrypted.push(ggTEncryptedValue);
    } else if (item === ' ') {
      encrypted.push(`.?`);
    } else {
      encrypted.push(`%${item}%`);
    }
  }
  return encrypted.join('#å!');
};

export const decrypt = (str = '') => {
  const str_array = str.split('#å!');
  const decrypted = [];
  for (let i = 0; i < str_array.length; i++) {
    const item = str_array[i];
    if (greenGreenThingsCreed[item]) {
      const ggTDecryptedValue = ggTDecrypt(item);
      decrypted.push(ggTDecryptedValue);
    } else {
      decrypted.push(item.replace(/%/g, ''));
    }
  }
  return decrypted.join('');
};
